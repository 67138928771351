import { ExampleAndItemResponse, ItemStatus } from '@gtn/app-common/api/model/ExampleAndItemResponse';
import { ExampleOverviewResponse } from '@gtn/app-common/api/model/ExampleOverviewResponse';
import styles from '@gtn/app-common/components/example-item-list/examples-overview/ExamplesOverview.module.scss';
import { getItemStatusIcon, ITEM_STATUS_DISPLAY_VALUES_TEACHER } from '@gtn/app-common/components/example-item-list/ItemStatusDisplay';
import { TypeFilter } from '@gtn/app-common/components/workbook/Workbook';
import { useAppCommonSelector } from '@gtn/app-common/store/app.store.hooks';
import { GtnAvatar } from '@gtn/common/components/gtn-avatar/GtnAvatar';
import { useIsTeacher } from '@gtn/common/store/user/user.hooks';
import { useAppTranslation } from '@gtn/common/utils/HookUtils';
import { Utils } from '@gtn/common/utils/Utils';
import { Tooltip } from '@material-ui/core';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import React, { useMemo } from 'react';

export interface ExamplesOverviewProps {
  items?: ExampleAndItemResponse[];
  onItemClicked?: (entry: ExampleAndItemResponse) => void;
  onExampleOrTopicClicked?: (exampleId: number, topicId?: number, title?: string, preSelectedItemStatus?: ItemStatus) => void;

  statusFilter: ItemStatus | null;
  typeFilter: TypeFilter | null;
  showStudentInsteadOfExample?: boolean;

  showAllExamplesLink?: string;
}

interface ExampleWithItems {
  example: ExampleOverviewResponse;

  subjectTitle: string;
  topicTitle: string;

  itemsStatusGroups: {
    [k: string]: ExampleAndItemResponse[];
  };
}

export function ExamplesOverview(props: ExamplesOverviewProps) {
  const t = useAppTranslation();
  const isTeacher = useIsTeacher();
  const allStudentsSelected = useAppCommonSelector((state) => state.navigation.selectedStudent) === null;

  const examples = useMemo(() => {
    let item_i = 0;
    if (props.items) {
      const grouped = Utils.groupBy(props.items, (item) => {
        if (props.showStudentInsteadOfExample || props.statusFilter) {
          if (props.statusFilter === ItemStatus.New) {
            return 'status-new-' + item.example?.id + ' ' + item.item?.owner?.userid ?? -1;
          }
          return 'status-not-new-' + item.item?.id + ' ' + item.item?.owner?.userid ?? -1;
        }

        if (item.example) {
          return 'example-' + item.example.id;
        }

        // was macht der code? -> vorerst deaktiviert weil sonst mehrere Beispiele im gleichen Topic für einen Schüler nicht angezeigt werden
        // if (props.statusFilter === null) {
        //   return item.topicid;
        // }

        if (item.item?.id) {
          return 'itemid-' + item.item?.id;
        } else {
          return 'item_i-' + item_i++;
        }
      });

      return Object.keys(grouped)
        .map((key) => {
          const exampleGroup = grouped[key];
          const firstItem = exampleGroup[0];

          const example: ExampleWithItems = {
            example: firstItem.example || {
              id: -1,
              title: firstItem.item?.name ?? t.withRole('workbook.type-filter.own-items'),
            },
            subjectTitle: firstItem.subjecttitle,
            topicTitle: firstItem.topictitle,
            itemsStatusGroups: Utils.groupBy(exampleGroup, (item) => item.status),
          };
          return example;
        })
        .sort((a, b) => getMaxTimemodified(b) - getMaxTimemodified(a));
    }
    return undefined;
  }, [props.items, props.typeFilter, props.statusFilter, props.showStudentInsteadOfExample]);

  function getStatusFilterIcon() {
    return getItemStatusIcon(props.statusFilter);
  }

  function getFirstItem(example: ExampleWithItems) {
    return example.itemsStatusGroups[Object.keys(example.itemsStatusGroups)[0]]?.[0];
  }

  function getMaxTimemodified(example: ExampleWithItems) {
    let max = 0;
    Object.values(example.itemsStatusGroups).forEach((group) =>
      group.forEach((item) => {
        max = Math.max(max, item.timemodified ?? 0);
      })
    );

    return max;
  }

  function renderAllStudentsStatusIndicators(example: ExampleWithItems) {
    return (
      <div className={styles.statusCountContainer}>
        {ITEM_STATUS_DISPLAY_VALUES_TEACHER.map((itemStatus) => {
          const count = example.itemsStatusGroups[itemStatus.statusMapping]?.length ?? 0;
          return (
            <Tooltip title={count + ' ' + t(itemStatus.textResId)} key={`example-${example.example.id}-${itemStatus.statusMapping}`}>
              <div
                className={styles.statusCount}
                style={{
                  color: count === 0 ? 'gray' : itemStatus.color,
                  opacity: count === 0 ? 0.4 : 1,
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  props.onExampleOrTopicClicked?.(example.example.id, undefined, example.example.title, getPreSelectedItemStatus(example));
                }}
              >
                <img src={'img/item-' + itemStatus.statusMapping + '.svg'} style={{ filter: count === 0 ? 'grayscale(1)' : 'none' }} />
                <span>{count}</span>
              </div>
            </Tooltip>
          );
        })}
      </div>
    );
  }

  function renderSingleStudentStatusIndicator(example: ExampleWithItems) {
    const itemStatus = ITEM_STATUS_DISPLAY_VALUES_TEACHER.find((s) => s.statusMapping === getFirstItem(example).status);

    if (itemStatus) {
      return (
        <div className={styles.statusCount} style={{ color: itemStatus.color }}>
          <img src={'img/item-' + itemStatus.statusMapping + '.svg'} />
          <span>{t(itemStatus.textResId)}</span>
        </div>
      );
    }
    return <></>;
  }

  function renderSingleStudentPerson(example: ExampleWithItems) {
    const student = getFirstItem(example)?.item?.owner;
    if (student) {
      return (
        <div className={styles.student}>
          <GtnAvatar className={styles.avatar} imageUrl={student.profileimageurl} />
          {!props.showStudentInsteadOfExample || props.typeFilter === TypeFilter.OwnItems ? student.fullname : ''}
        </div>
      );
    }

    return <></>;
  }

  function getPreSelectedItemStatus(example: ExampleWithItems) {
    for (const status of Object.keys(example.itemsStatusGroups)) {
      if (example.itemsStatusGroups[status].length > 0) {
        return ITEM_STATUS_DISPLAY_VALUES_TEACHER.find((s) => s.statusMapping === status)?.statusMapping;
      }
    }
    return undefined;
  }

  function renderStatusIndicator(example: ExampleWithItems) {
    if (isTeacher) {
      return props.statusFilter ? renderSingleStudentPerson(example) : allStudentsSelected ? renderAllStudentsStatusIndicators(example) : renderSingleStudentStatusIndicator(example);
    }
    return renderSingleStudentStatusIndicator(example);
  }

  function renderTitle(hasExample: boolean, firstItem: ExampleAndItemResponse, example: ExampleWithItems) {
    if (isTeacher) {
      return props.statusFilter === null && props.typeFilter === null && !hasExample
        ? t.withRole('workbook.type-filter.own-items')
        : props.statusFilter === null && props.typeFilter === TypeFilter.OwnItems && !hasExample
        ? firstItem?.topictitle
        : props.showStudentInsteadOfExample && props.typeFilter !== TypeFilter.OwnItems && hasExample
        ? firstItem?.item?.owner.fullname
        : example.example.title;
    } else {
      return hasExample ? example.example.title : firstItem.item?.name;
    }
  }

  function renderSubtitle(hasExample: boolean, firstItem: ExampleAndItemResponse, example: ExampleWithItems) {
    const timeModifiedText = new Date((firstItem?.timemodified ?? 0) * 1000);
    if (isTeacher) {
      return props.statusFilter === null && props.typeFilter === TypeFilter.OwnItems && !hasExample
        ? firstItem?.subjecttitle
        : (props.statusFilter !== null && props.typeFilter !== TypeFilter.OwnItems && !hasExample ? t.withRole('workbook.type-filter.own-items') + ' • ' : '') +
            (props.statusFilter !== null && props.statusFilter !== ItemStatus.New ? timeModifiedText.toLocaleDateString() : example.topicTitle);
    } else {
      return firstItem.status !== ItemStatus.New ? timeModifiedText.toLocaleDateString() : example.topicTitle;
    }
  }

  function renderList() {
    if (examples?.length) {
      return (
        <>
          {examples?.map((example) => {
            const firstItem = getFirstItem(example);
            const hasExample = example.example.id !== -1;

            return (
              <ListItem
                className={styles.listItem}
                onClick={() =>
                  (isTeacher ? props.statusFilter != null || !allStudentsSelected : true)
                    ? props.onItemClicked?.(firstItem)
                    : props.onExampleOrTopicClicked?.(example.example.id, firstItem?.topicid, hasExample ? example.example.title : example.topicTitle, getPreSelectedItemStatus(example))
                }
                button
              >
                <img className={styles.icon} src={getStatusFilterIcon()} />
                <ListItemText primary={renderTitle(hasExample, firstItem, example)} secondary={renderSubtitle(hasExample, firstItem, example)} />

                {renderStatusIndicator(example)}
              </ListItem>
            );
          })}

          {!props.statusFilter && props.showAllExamplesLink && (
            <a className={styles.linkToExamples} href={props.showAllExamplesLink}>
              {t('Weitere Aufgaben freischalten')}
            </a>
          )}
        </>
      );
    } else {
      return <p>{t('list-empty')}</p>;
    }
  }

  return <div className={styles.list}>{renderList()}</div>;
}
