import { getCommonRoutes } from '@gtn/app-common/CommonRoutes';
import { AppNavigation } from '@gtn/app-common/components/app-navigation/AppNavigation';
import Examples from '@gtn/app-common/components/examples/Examples';
import Workbook from '@gtn/app-common/components/workbook/Workbook';
import { CommonRoutingPaths } from '@gtn/app-common/routes/AppCommonRouting';
import { NavigationItem } from '@gtn/common/components/navigation/NavigationItem';
import { useIsTeacher, useRole } from '@gtn/common/store/user/user.hooks';
import { useAppTranslation } from '@gtn/common/utils/HookUtils';
import { GtnRoute } from '@gtn/common/utils/routing/GtnRoute';
import { CompetenceGrids } from '@gtn/dakora/routes/competence-grids/CompetenceGrids';
import { DakoraRoutingPaths } from '@gtn/dakora/routes/DakoraRouting';
import { LearningDiary } from '@gtn/dakora/routes/learning-diary/LearningDiary.component';
import LearningPaths from '@gtn/dakora/routes/learning-paths/LearningPaths';
import { LearningPlans } from '@gtn/dakora/routes/learning-plans/LearningPlans';
import { Quizzes } from '@gtn/dakora/routes/quizzes/Quizzes';
import Reports from '@gtn/dakora/routes/reports/Reports';
import { DakoraSettings } from '@gtn/dakora/routes/settings/DakoraSettings';
import { useDakoraSelector } from '@gtn/dakora/store/DakoraStore';
import { DakoraExacompVersions } from '@gtn/dakora/utils/DakoraExacompVersions';
import { Book, EventAvailable, FolderShared, GridOn, MapRounded, Poll, Spellcheck } from '@material-ui/icons';
import CourseTopics from '@gtn/app-common/routes/course-topics/CourseTopics';
import AssignmentIcon from '@material-ui/icons/Assignment';
import { polyfill } from 'mobile-drag-drop';
import { useEffect } from 'react';
import './styles.scss';
import { AssignmentGrading } from './routes/assignment-grading/AssignmentGrading.component';
import { Home } from './routes/Home';
import { useServerInfoManager } from '@gtn/app-common/store/app.store.hooks';

export function DakoraApp() {
  const t = useAppTranslation();
  const isTeacher = useIsTeacher();
  const userRole = useRole();
  const selfDiagnosisEnabled = useDakoraSelector((state) => state.appdakora.enableSelfDiagnosis);
  const serverInfoManager = useServerInfoManager();

  useEffect(() => {
    const isSafariOnIPad = navigator.maxTouchPoints > 0 && navigator.userAgent.includes('Safari') && !navigator.userAgent.includes('Chrome');
    polyfill({
      forceApply: isSafariOnIPad,
    });

    const listener = function () {};
    window.addEventListener('touchmove', listener, { passive: false });
    return () => {
      window.removeEventListener('touchmove', listener);
    };
  }, []);

  const routes: GtnRoute[] = [
    {
      path: CommonRoutingPaths.HOME,
      exact: true,
      content: () => <Home />,
    },
    {
      path: `/${DakoraRoutingPaths.LEARNING_PLANS}`,
      content: () => <LearningPlans />,
      title: `menu.learning-plans.${userRole}`,
    },
    {
      path: `/${DakoraRoutingPaths.LEARNING_PATHS}`,
      content: () => <LearningPaths />,
      title: `menu.learning-paths.${userRole}`,
    },
    {
      path: `/${DakoraRoutingPaths.REPORTS}`,
      content: () => <Reports />,
      title: `menu.reports.${userRole}`,
      isAccessible: isTeacher,
    },
    {
      path: `/${DakoraRoutingPaths.QUIZZES}`,
      content: () => <Quizzes />,
      title: `menu.quizzes.${userRole}`,
      isAccessible: selfDiagnosisEnabled,
    },
    {
      path: `/${DakoraRoutingPaths.COMPETENCE_GRIDS}`,
      content: () => <CompetenceGrids />,
      title: `menu.competence-grids.${userRole}`,
    },
    {
      path: `/${DakoraRoutingPaths.LEARNING_DIARY}`,
      content: () => <LearningDiary />,
      title: `menu.learning-diary.${userRole}`,
      isAccessible: !isTeacher,
    },
    {
      path: `/${DakoraRoutingPaths.ASSIGNMENT_GRADING}`,
      content: () => <AssignmentGrading />,
      title: `menu.assignment-grading`,
      isAccessible: isTeacher,
    },
    {
      path: `/${CommonRoutingPaths.WORKBOOK}`,
      exact: true,
      content: () => <Workbook showTypeFilter={true} />,
      title: `menu.workbook.${userRole}`,
    },
    {
      path: `/${CommonRoutingPaths.EXAMPLES}`,
      content: () => <Examples />,
      title: `menu.examples.teacher`,
      isAccessible: isTeacher,
    },
    {
      path: `/${CommonRoutingPaths.COURSE_TOPICS}`,
      content: () => <CourseTopics />,
      title: `settings.choose-competencies`,
      backRoute: `/${CommonRoutingPaths.SETTINGS}`,
      isAccessible: isTeacher,
    },
    {
      path: `/${CommonRoutingPaths.SETTINGS}`,
      content: () => <DakoraSettings />,
      title: 'menu.settings',
    },
    ...getCommonRoutes(),
  ];

  const navItems: NavigationItem[] = [
    {
      title: t.withRole('menu.workbook'),
      href: CommonRoutingPaths.WORKBOOK,
      icon: <FolderShared />,
    },
    {
      title: t.withRole('menu.learning-plans'),
      href: DakoraRoutingPaths.LEARNING_PLANS,
      icon: <EventAvailable />,
    },
    {
      title: t.withRole('menu.quizzes'),
      href: DakoraRoutingPaths.QUIZZES,
      icon: <Spellcheck />,
      hide: !selfDiagnosisEnabled,
    },
    {
      title: t.withRole('menu.competence-grids'),
      href: DakoraRoutingPaths.COMPETENCE_GRIDS,
      icon: <GridOn />,
    },
    {
      title: t.withRole('menu.examples'),
      href: CommonRoutingPaths.EXAMPLES,
      icon: <AssignmentIcon />,
      hide: !isTeacher,
    },
    {
      title: t.withRole('menu.learning-diary'),
      href: DakoraRoutingPaths.LEARNING_DIARY,
      icon: <Book />,
      hide: isTeacher,
    },
    ...(serverInfoManager.hasMinRequiredExacompVersion(DakoraExacompVersions.MIN_VERSION_REPORTS_LEARNING_PATHS)
      ? [
          {
            title: t.withRole('menu.learning-paths'),
            href: DakoraRoutingPaths.LEARNING_PATHS,
            icon: <MapRounded />,
          },
          {
            title: t.withRole('menu.reports'),
            href: DakoraRoutingPaths.REPORTS,
            icon: <Poll />,
            hide: !isTeacher,
          },
        ]
      : []),
  ];

  return <AppNavigation routes={routes} primaryNavigationItems={navItems} showNotifications={true} />;
}
