import { ItemStatus } from '@gtn/app-common/api/model/ExampleAndItemResponse';

export interface ItemStatusDisplay {
  textResId: string;
  color: string;
  icon?: string;
  statusMapping: ItemStatus;
}

export function getItemStatusIcon(status?: ItemStatus | null) {
  let icon: string | undefined = undefined;
  if (status) {
    icon = ITEM_STATUS_DISPLAY_VALUES_STUDENT.find((s) => s.statusMapping === status)?.icon;
  }
  return icon ?? 'img/example.svg';
}

export const ITEM_STATUS_DISPLAY_VALUES_TEACHER: ItemStatusDisplay[] = [
  {
    statusMapping: ItemStatus.Inprogress,
    textResId: 'workbook.status-filter.in-progress',
    color: '#2C6EF6',
    icon: 'img/item-inprogress.svg',
  },
  {
    statusMapping: ItemStatus.Submitted,
    textResId: 'workbook.status-filter.submitted',
    color: '#F2A139',
    icon: 'img/item-submitted.svg',
  },
  {
    statusMapping: ItemStatus.Completed,
    textResId: 'workbook.status-filter.completed',
    color: '#78A334',
    icon: 'img/item-completed.svg',
  },
];

export const ITEM_STATUS_DISPLAY_VALUES_STUDENT: ItemStatusDisplay[] = [
  {
    statusMapping: ItemStatus.New,
    textResId: 'workbook.status-filter.new',
    color: 'grey',
  },
  ...ITEM_STATUS_DISPLAY_VALUES_TEACHER,
];
