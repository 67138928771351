import { AppCommonAPI } from '@gtn/app-common/api/AppCommonAPI';
import { ItemStatus } from '@gtn/app-common/api/model/ExampleAndItemResponse';
import { ITEM_STATUS_DISPLAY_VALUES_STUDENT } from '@gtn/app-common/components/example-item-list/ItemStatusDisplay';
import ExampleItemDialog from '@gtn/app-common/components/submit-item/ExampleItemDialog';
import { useAppCommonSelector, useAppDispatch, useSelectedCourse, useSelectedStudents } from '@gtn/app-common/store/app.store.hooks';
import { PlanningStorageExample } from '@gtn/common/api/model/exacomp';
import { useAPI } from '@gtn/common/api/webservice/WebserviceHookUtils';
import { GtnSnackbar } from '@gtn/common/components/GtnSnackbar';
import { LoadingIndicatorOverlay } from '@gtn/common/components/LoadingIndicator';
import { useGtnDialog } from '@gtn/common/components/navigation/gtn-dialog/GtnDialog';
import { StyleProps } from '@gtn/common/components/StyleProps';
import { TranslationManager } from '@gtn/common/i18n/TranslationManager';
import { useIsTeacher, useUser } from '@gtn/common/store/user/user.hooks';
import { ThemeManager } from '@gtn/common/theme/ThemeManager';
import { dispatchEvent } from '@gtn/common/utils/events';
import { useAppTranslation } from '@gtn/common/utils/HookUtils';
import InjectionContainer from '@gtn/common/utils/InjectionContainer';
import { GtnLogger } from '@gtn/common/utils/logger/GtnLogger';
import { ProgressState } from '@gtn/common/utils/ProgressState';
import { Utils } from '@gtn/common/utils/Utils';
import { DakoraAPI } from '@gtn/dakora/api/DakoraAPI';
import { ScheduledExample } from '@gtn/dakora/api/model/ScheduledExample';
import { AddLearningPlanContentDialog } from '@gtn/dakora/routes/learning-plans/add-learning-plan-content/AddLearningPlanContentDialog';
import { appDAKORAActions } from '@gtn/dakora/store/DakoraState';
import { useDakoraSelector } from '@gtn/dakora/store/DakoraStore';
import { IconButton, Popover } from '@material-ui/core';
import { ArrowDownward, ArrowLeft, ArrowRight, ArrowUpward, Delete, Lock } from '@material-ui/icons';
import classNames from 'classnames';
import { addDays, addMinutes, addSeconds, differenceInMinutes, endOfWeek as endOfWeekOrig, isWeekend, startOfWeek as startOfWeekOrig } from 'date-fns';
import format from 'date-fns/format';
import getDay from 'date-fns/getDay';
import { deAT } from 'date-fns/locale';
import parse from 'date-fns/parse';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Calendar, dateFnsLocalizer, Event, stringOrDate, Views } from 'react-big-calendar';
import withDragAndDrop, { withDragAndDropProps } from 'react-big-calendar/lib/addons/dragAndDrop';
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import styles from './PlanningCalendar.module.scss';

export interface PlanningCalendarProps extends StyleProps {
  courseId: number;
  userId: number;
}

// startofweek nochmals hier mit explizitem "weekStartsOn" setzen, da sonst der kalender am Sonntag nicht geht!
const startOfWeek = (date) => startOfWeekOrig(date, { weekStartsOn: 1 });
const endOfWeek = (date) => endOfWeekOrig(date, { weekStartsOn: 1 });

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales: {
    'de-AT': deAT,
  },
});

//@ts-ignore
const DnDCalendar = withDragAndDrop(Calendar);

function useExampleAndItem(example?: Record<string, any>) {
  const courses = useAppCommonSelector((state) => state.navigation.courses);
  const isTeacher = useIsTeacher();
  const selectedStudents = useSelectedStudents();
  const appCommonAPI = InjectionContainer.resolve(AppCommonAPI);

  let executeTeacherAPI = false;
  let executeStudentAPI = false;

  if (example) {
    if (isTeacher && !courses.some((course) => course.id == example.courseid)) {
      // beim Lehrer den Status eines ausgewählten Kalendereintrags nur laden, wenn dieser in einem der Lehrerkurse ist
      // sonst schlägt der Service fehl!
    } else if (isTeacher && selectedStudents?.length === 1) {
      executeTeacherAPI = true;
    } else if (!isTeacher) {
      executeStudentAPI = true;
    }
  }

  const retTeacherAPI = useAPI(appCommonAPI.teacherGetStudentExampleAndItem, [selectedStudents?.[0]?.id || 0, example?.exampleid, example?.courseid], { enabled: executeTeacherAPI });
  const retStudentAPI = useAPI(appCommonAPI.studentGetExampleAndItem, [example?.exampleid, example?.courseid], { enabled: executeStudentAPI });

  if (executeTeacherAPI) {
    return retTeacherAPI;
  } else {
    return retStudentAPI;
  }
}

export function PlanningCalendar(props: PlanningCalendarProps) {
  const t = useAppTranslation();
  const dakoraAPI = InjectionContainer.resolve(DakoraAPI);
  const themeManager = InjectionContainer.resolve(ThemeManager);
  const translationManager = InjectionContainer.resolve(TranslationManager);
  const dispatch = useAppDispatch();

  const isTeacher = useIsTeacher();
  const user = useUser();
  const selectedCourse = useSelectedCourse();

  const draggingExample = useDakoraSelector((state) => state.appdakora.learningPlan.draggingPlanningStorageExample);
  const [visibleTimeframe, setVisibleTimeframe] = useState<Date[]>([startOfWeek(new Date()), endOfWeek(new Date())]);
  const [saveFailed, setSaveFailed] = React.useState(false);
  const [selectedEvent, setSelectedEvent] = React.useState<{ example: ScheduledExample; element: HTMLElement } | undefined>();
  const [selectedSlot, setSelectedSlot] = React.useState<{ start: Date; end: Date } | undefined>();

  const addContentDialog = useGtnDialog(AddLearningPlanContentDialog);
  const exampleItemDialog = useGtnDialog(ExampleItemDialog);

  const timeGutterColumnRef = useRef<any>();

  const { data: scheduledExamples, progressState, mutate: reloadExamples } = useAPI(dakoraAPI.getScheduledExamples, [props.userId, visibleTimeframe[0], visibleTimeframe[1]]);
  const { data: calendarConfig } = useAPI(dakoraAPI.getScheduleCalendarConfig);

  const { data: selectedEventExampleAndItem, isValidating: selectedEventExampleAndItemIsLoading } = useExampleAndItem(selectedEvent?.example);

  const config = useMemo(() => {
    if (calendarConfig) {
      const interval = Number(calendarConfig.interval);
      const startTimeComponents = calendarConfig.begin.split(':');
      const startHour = Number(startTimeComponents[0]);
      const startMinute = Number(startTimeComponents[1]);

      const minTime = new Date();
      minTime.setHours(startHour, startMinute, 0);
      const maxTime = addMinutes(minTime, calendarConfig.units * interval);

      return {
        min: minTime,
        max: maxTime,
        step: interval / 4,
        timeslots: 4,
        periods: calendarConfig.periods,
      };
    }
    return undefined;
  }, [calendarConfig]);

  const events: Event[] = useMemo(() => {
    return scheduledExamples?.map((example) => ({ start: new Date(example.start * 1000), end: new Date(example.end * 1000), title: example.title, resource: example })) ?? [];
  }, [scheduledExamples]);

  useEffect(() => {
    if (selectedEvent) {
      // update selected event after examples are reloaded
      const example = scheduledExamples?.find((s) => s.scheduleid === selectedEvent.example.scheduleid);
      if (example) {
        setTimeout(() => {
          const containerElement = document.getElementById(`event-schedule-id-${example.scheduleid}`)?.parentElement;
          setSelectedEvent(containerElement ? { element: containerElement, example: example } : undefined);
        }, 300);
      } else {
        setSelectedEvent(undefined);
      }
    }
  }, [scheduledExamples]);

  const onVisibleTimeframeChanged = (range: Date[] | { start: stringOrDate; end: stringOrDate }) => {
    if (Array.isArray(range)) {
      const start = range[0];
      const end = addDays(range[range.length - 1], 1);
      setVisibleTimeframe([start, end]);
    }
  };

  const onEventResize: withDragAndDropProps['onEventResize'] = async (data) => {
    await scheduleExample((data.event.resource as PlanningStorageExample).scheduleid, data.start, data.end);
  };

  const onEventDrop: withDragAndDropProps['onEventDrop'] = async (data) => {
    await scheduleExample((data.event.resource as ScheduledExample).scheduleid, data.start, data.end);
  };

  const onDropFromOutside: withDragAndDropProps['onDropFromOutside'] = async (data) => {
    if (draggingExample) {
      let end = data.end;
      if (draggingExample.timeframe && data.start instanceof Date) {
        const { hours, minutes } = Utils.parseDurationString(draggingExample.timeframe);
        const totalMinutes = hours * 60 + minutes;
        end = addMinutes(data.start, totalMinutes > 0 ? totalMinutes : 60);
      }

      let scheduleId = draggingExample.scheduleid;

      if (scheduleId && draggingExample.userId) {
        // verschieben vom Schüler Planungsspeicher zum Schüler Lernplan
        await scheduleExample(scheduleId, data.start, end);

        // reload des Planungsspeichers notwendig
        // TODO: die userId mitsenden, dass nur dieser Planungsspeicher neu geladen wird!
        await dispatchEvent({ type: 'RELOAD_PLANNING_STORAGE' });
      } else if (props.userId) {
        // verschieben vom Lehrer Planungsspeicher zum Schüler Lernplan
        const result = await dakoraAPI.addExampleToPlanningStorage(props.courseId, draggingExample.exampleid, props.userId);
        scheduleId = result.scheduleid;

        await scheduleExample(scheduleId, data.start, end);

        dispatch(appDAKORAActions.setDraggingExercise());
        // kein reload des Planungsspeichers notwendig, da eine Kopie in den Lernplan gelegt wurde!
      } else {
        const fromLearningPath = scheduleId == null;
        if (fromLearningPath) {
          const result = await dakoraAPI.addExampleToPlanningStorage(props.courseId, draggingExample.exampleid, props.userId);
          scheduleId = result.scheduleid;
        }
        // verschieben vom Lehrer Planungsspeicher zu allen Schülern "neuer Lernplan"

        // zuerst timeslot festlegen
        await scheduleExample(scheduleId, data.start, end);

        // und Planungsspeicher neu laden
        // TODO: die userId mitsenden, dass nur der relevante Planungsspeicher (für den Schüler oder den Lehrer) neu geladen wird!
        dispatchEvent({ type: 'RELOAD_PLANNING_STORAGE' });
      }
    }
  };

  const scheduleExample = async (scheduleId: number, start: any, end: any, reload: boolean = true) => {
    if (start instanceof Date && end instanceof Date) {
      try {
        const result = await dakoraAPI.scheduleExample(scheduleId, start, end);
        if (result.success) {
          if (reload) {
            await reloadExamples();
          }
          return true;
        }
      } catch (e) {
        GtnLogger.warn(e);
      }
    }
    setSaveFailed(true);
    return false;
  };

  async function rescheduleSelectedEvent(amount: number) {
    if (selectedEvent) {
      const direction = amount > 0 ? 1 : -1;
      let newStart = addSeconds(Utils.toDate(selectedEvent.example.start), amount * 60);
      let newEnd = addSeconds(Utils.toDate(selectedEvent.example.end), amount * 60);

      if (config && newStart.getHours() * 60 + newStart.getMinutes() < config.min.getHours() * 60 + config.min.getMinutes()) {
        newStart.setHours(config.min.getHours(), config.min.getMinutes(), 0, 0);
        const durationInMinutes = differenceInMinutes(Utils.toDate(selectedEvent.example.end), Utils.toDate(selectedEvent.example.start));
        newEnd = addMinutes(newStart, durationInMinutes);
      }

      if (config && newEnd.getHours() * 60 + newEnd.getMinutes() > config.max.getHours() * 60 + config.max.getMinutes()) {
        newEnd.setHours(config.max.getHours(), config.max.getMinutes(), 0, 0);
        const durationInMinutes = differenceInMinutes(Utils.toDate(selectedEvent.example.end), Utils.toDate(selectedEvent.example.start));
        newStart = addMinutes(newEnd, -durationInMinutes);
      }

      if (isWeekend(newStart)) {
        newStart = addDays(newStart, 2 * direction);
        newEnd = addDays(newEnd, 2 * direction);
      }
      await scheduleExample(selectedEvent.example.scheduleid, newStart, newEnd);
    }
  }

  const removeScheduledExample = async (example: ScheduledExample) => {
    try {
      let result: Awaited<ReturnType<typeof dakoraAPI.removeExampleFromSchedule>>;
      if (!canRemoveExampleFromCalenderAndStorage(example)) {
        // beispiel kann nicht gelöscht werden -> in den planungsspeicher verschieben
        result = await dakoraAPI.removeExampleFromSchedule(example.scheduleid);
        dispatchEvent({ type: 'RELOAD_PLANNING_STORAGE' });
      } else {
        result = await dakoraAPI.deleteScheduledExample(example.scheduleid);
      }
      if (result.success) {
        setSelectedEvent(undefined);
        await reloadExamples();
        return;
      }
    } catch (e) {
      GtnLogger.warn(e);
    }
    setSaveFailed(true);
  };

  const onSelectSlot = (e) => {
    if (e.start instanceof Date && e.end instanceof Date) {
      setSelectedSlot({ start: e.start, end: e.end });
      addContentDialog.open();
    }
  };

  function renderExampleAndItem() {
    if (!selectedEvent) {
      return null;
    } else if (selectedEventExampleAndItem) {
      let textResId = isTeacher ? t('learning-plans.example-not-started') : t('learning-plans.start-example');

      if (selectedEvent.example.itemstatus !== ItemStatus.New) {
        const itemStatusDisplay = ITEM_STATUS_DISPLAY_VALUES_STUDENT.find((v) => v.statusMapping === selectedEvent.example.itemstatus);
        if (itemStatusDisplay) {
          textResId = itemStatusDisplay.textResId;
        }
      }

      return (
        <a className={styles.itemDialogLink} onClick={() => onExampleAndItemClick()}>
          {t(textResId)}
        </a>
      );
    } else if (selectedEventExampleAndItemIsLoading) {
      return <div className={styles.itemDialogLink}>{t('loading')}</div>;
    } else {
      // loading was disabled, because exampleAndItem not available
      // z.B. Lehrer von einem anderen Kurs
      return null;
    }
  }

  function onExampleAndItemClick() {
    if (selectedEventExampleAndItem) {
      exampleItemDialog.open({ exampleAndItem: selectedEventExampleAndItem, studentId: props.userId });
    }
  }

  const canRescheduleEvent = (event: Event) => {
    const example = event.resource as ScheduledExample;
    return canEditExample(example);
  };

  function canRemoveExampleFromCalenderAndStorage(example: PlanningStorageExample) {
    if (!isTeacher && example.creatorid && user.profile?.id && example.creatorid !== user.profile?.id) {
      // students who haven't added this example in the planningStorage (=was created by the teacher)
      return false;
    }
    return true;
  }

  const canEditExample = (example: ScheduledExample) => {
    if (!isTeacher && example.addedtoschedulebyid && user.profile?.id && example.addedtoschedulebyid !== user.profile?.id) {
      // students who haven't added this example in the calendar (=was created by the teacher)
      return false;
    }
    return true;
  };

  const getEventStyle = (event: Event) => {
    const example = event.resource as ScheduledExample;

    return {
      style: {
        backgroundColor: themeManager.getColorForId(example.courseid, 'grey'),
        opacity: selectedCourse?.id === example.courseid ? 1 : 0.5,
      },
    };
  };

  function formatTimeframe(timeframe: string) {
    const { hours, minutes } = Utils.parseDurationString(timeframe);
    let result = '';
    if (hours > 0) {
      result += `${hours}${t('duration.hours-abbreviation')} `;
    }
    result += `${minutes}${t('duration.minutes-abbreviation')}`;

    return result;
  }

  return (
    <div className={classNames(props.className, styles.container, draggingExample ? styles.dragHover : undefined)} style={props.style}>
      {config && (
        <DnDCalendar
          className="planningCalendar"
          culture={translationManager.getLanguage() === 'de' ? 'de-AT' : 'en-US'}
          onRangeChange={onVisibleTimeframeChanged}
          events={events}
          views={[Views.WORK_WEEK, Views.DAY]}
          defaultView={Views.WORK_WEEK}
          localizer={localizer}
          resizable
          selectable
          onSelectSlot={onSelectSlot}
          onSelectEvent={(event: Event, e: React.SyntheticEvent<HTMLElement>) =>
            setSelectedEvent({
              example: event.resource,
              element: e.target as HTMLElement,
            })
          }
          onEventDrop={onEventDrop}
          onEventResize={onEventResize}
          onDropFromOutside={onDropFromOutside}
          resizableAccessor={canRescheduleEvent}
          draggableAccessor={canRescheduleEvent}
          eventPropGetter={getEventStyle}
          components={{
            timeGutterWrapper: (data: any) => {
              if (config?.periods?.length) {
                return (
                  <div className="rbc-time-gutter rbc-time-column" ref={timeGutterColumnRef}>
                    {data.slotMetrics.groups.map((grp, idx) => (
                      <div className="rbc-time-slot" style={{ paddingRight: 4, textAlign: 'left' }}>
                        {config?.periods?.[idx]?.title?.trim() ?? <>&nbsp;</>}
                      </div>
                    ))}
                  </div>
                );
              }
              return data.children;
            },
            timeGutterHeader: () => {
              if (config?.periods?.length) {
                return <div style={{ width: ((timeGutterColumnRef.current as HTMLElement)?.clientWidth ?? 74) - 10 }} />;
              }
              return <></>;
            },
            event: (data) => {
              const event = data.event as Event;
              const example = event.resource as ScheduledExample;
              const itemStatusDisplay = ITEM_STATUS_DISPLAY_VALUES_STUDENT.find((s) => s.statusMapping === example.itemstatus);

              return (
                <div className={styles.event} id={`event-schedule-id-${example.scheduleid}`}>
                  <p className={styles.title}>{event.title}</p>
                  <p className={styles.description}>{[t(itemStatusDisplay?.textResId ?? ''), example.taxonomies?.[0]?.title].filter(Utils.filterNullOrUndefined).join(' • ')}</p>
                </div>
              );
            },
          }}
          messages={{ next: '→', previous: '←', work_week: t('learning-plans.calendar.week'), day: t('learning-plans.calendar.day'), today: t('learning-plans.calendar.today') }}
          {...config}
        />
      )}

      <Popover
        open={!!selectedEvent}
        anchorEl={selectedEvent?.element}
        onClose={() => setSelectedEvent(undefined)}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
      >
        {selectedEvent && (
          <div className={styles.eventPopover}>
            {canEditExample(selectedEvent.example) ? (
              <div className={styles.actions}>
                <IconButton size="small" onClick={() => rescheduleSelectedEvent(-(config?.step ?? 30))}>
                  <ArrowUpward />
                </IconButton>
                <IconButton size="small" onClick={() => rescheduleSelectedEvent(config?.step ?? 30)}>
                  <ArrowDownward />
                </IconButton>
                <IconButton size="small" onClick={() => rescheduleSelectedEvent(-(24 * 60))}>
                  <ArrowLeft />
                </IconButton>
                <IconButton size="small" onClick={() => rescheduleSelectedEvent(24 * 60)}>
                  <ArrowRight />
                </IconButton>

                <div style={{ flex: 1 }}></div>

                <IconButton size="small" onClick={() => removeScheduledExample(selectedEvent.example)}>
                  <Delete />
                </IconButton>
              </div>
            ) : (
              <div className={styles.actions}>
                <div style={{ flex: 1 }}></div>
                <Lock />
              </div>
            )}

            <p className={styles.title}>{selectedEvent.example.title}</p>
            <p className={styles.description}>{selectedEvent.example.courseshortname}</p>
            {selectedEvent.example.taxonomies?.[0] && (
              <p className={styles.description}>
                {t('create-edit-example.niveau')}: {selectedEvent.example.taxonomies[0].title}
              </p>
            )}
            {/* <p className={styles.description}>{Utils.toDate(selectedEvent.example.start).toLocaleString() + ' - ' + Utils.toDate(selectedEvent.example.end).toLocaleTimeString()}</p> */}
            {selectedEvent.example.timeframe && (
              <p className={styles.description}>
                {t('Zeitvorschlag')}: {formatTimeframe(selectedEvent.example.timeframe)}
              </p>
            )}

            {renderExampleAndItem()}
          </div>
        )}
      </Popover>

      <div style={{ position: 'absolute', top: 0, left: 0 }}>
        {progressState === ProgressState.Loading && <LoadingIndicatorOverlay />}

        <addContentDialog.Component
          addToText="Lernplan"
          courseId={props.courseId}
          userId={props.userId}
          onSave={(scheduleId?: number, suggestedTimeframe?: string) => {
            if (scheduleId != null && selectedSlot) {
              let endDate = selectedSlot.end;
              if (suggestedTimeframe) {
                const { hours, minutes } = Utils.parseDurationString(suggestedTimeframe);
                endDate = addMinutes(selectedSlot.start, hours * 60 + minutes);
              }
              dakoraAPI.scheduleExample(scheduleId, selectedSlot.start, endDate);
            }
            reloadExamples();
          }}
        />

        <exampleItemDialog.Component
          onSave={() => {
            setSelectedEvent(undefined);
            reloadExamples();
          }}
        />

        <GtnSnackbar message={t('save-failed')} open={saveFailed} onClose={() => setSaveFailed(false)} />
      </div>
    </div>
  );
}
