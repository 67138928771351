import { AppCommonAPI } from '@gtn/app-common/api/AppCommonAPI';
import { AppConfigManager } from '@gtn/app-common/config/AppConfigManager';
import { IAppConfig } from '@gtn/app-common/config/IAppConfig';
import { MSAuthManager } from '@gtn/app-common/microsoft/MSAuthManager';
import { MSTeamsManager } from '@gtn/app-common/microsoft/MSTeamsManager';
import { CommonRoutingParams } from '@gtn/app-common/routes/AppCommonRouting';
import { AppCommonState } from '@gtn/app-common/store/app.store';
import { navigationActions } from '@gtn/app-common/store/navigation.state';
import { ErrorTrackingService } from '@gtn/app-common/utils/error-tracking/ErrorTrackingService';
import { ServerInfoManager } from '@gtn/app-common/utils/ServerInfoManager';
import { ExacompAPI } from '@gtn/common/api/ExacompAPI';
import { ConfigManagerToken } from '@gtn/common/config/ConfigManager';
import { TranslationManager } from '@gtn/common/i18n/TranslationManager';
import { preferencesActions } from '@gtn/common/store/preferences/preferences.state';
import { StoreToken } from '@gtn/common/store/shared.store';
import { ThemeManager } from '@gtn/common/theme/ThemeManager';
import InjectionContainer from '@gtn/common/utils/InjectionContainer';
import { GtnLogger } from '@gtn/common/utils/logger/GtnLogger';
import { MetaTagsManager } from '@gtn/common/utils/MetaTagsManager';
import { Utils } from '@gtn/common/utils/Utils';
import { Store } from 'redux';
import { singleton } from 'tsyringe';

@singleton()
export class AppInitializer {
  private readonly configManager = InjectionContainer.resolve<AppConfigManager>(ConfigManagerToken);
  private readonly store = InjectionContainer.resolve<Store<AppCommonState>>(StoreToken);
  private readonly translationManager = InjectionContainer.resolve(TranslationManager);
  private readonly themeManager = InjectionContainer.resolve(ThemeManager);
  private readonly metaTagsManager = InjectionContainer.resolve(MetaTagsManager);
  private readonly serverInfoManager = InjectionContainer.resolve(ServerInfoManager);
  private readonly msTeamsManager = InjectionContainer.resolve(MSTeamsManager);
  private readonly msAuthManager = InjectionContainer.resolve(MSAuthManager);
  private readonly errorTrackerService = InjectionContainer.resolve(ErrorTrackingService);
  private readonly exacompAPI = InjectionContainer.resolve(ExacompAPI);
  private readonly appCommonAPI = InjectionContainer.resolve(AppCommonAPI);

  private config?: IAppConfig;

  public async init() {
    GtnLogger.log('Initializing App...');

    await this.initConfig();
    this.errorTrackerService.init();

    await this.initI18n();
    this.initTheme();
    this.initMetaTags();
    this.initMSTeams();

    if (this.store.getState().preferences.moodleUrl) {
      await this.initMoodleDependantSettings();
    }

    if (this.store.getState().user.isLoggedIn) {
      await this.initUserDependantSettings();
    }
  }

  public async initMoodleDependantSettings() {
    await this.initServerInfo();
    this.initMSAuth();
    await this.initLanguageOverrides();
  }

  public async initUserDependantSettings() {
    await this.initCourseConfigs();
    await this.checkForEnrolCode();
  }

  private async initConfig() {
    try {
      const queryParams = Utils.parseQuery(window.location.search);
      const configPath = queryParams[CommonRoutingParams.CONFIG];
      this.config = await this.configManager.loadRemoteConfig(configPath);

      GtnLogger.log('basePath: ' + this.config.basePath);
    } catch (e) {}
  }

  private initI18n() {
    const i18nConfig = this.config!.i18n;
    let uiLanguage = this.store.getState().preferences.language;
    if (!uiLanguage) {
      uiLanguage = TranslationManager.getBestUsableLanguage(i18nConfig.availableLanguages.map((l) => l.code));
    }

    return this.translationManager.init(i18nConfig.defaultLanguage, uiLanguage, this.config?.basePath);
  }

  private initTheme() {
    this.themeManager.init(this.config!.theme, this.configManager.getAssetPath(''));
  }

  private initMetaTags() {
    this.metaTagsManager.init({
      appName: this.config?.theme.appName,
      primaryColor: this.config?.theme.colors.primary,
    });
  }

  private async initServerInfo() {
    return this.serverInfoManager.init();
  }

  private initMSTeams() {
    this.msTeamsManager.init(this.config?.basePath);
  }

  private initMSAuth() {
    const clientId = this.serverInfoManager.getServerInfo()?.msteams_azure_app_client_id;
    if (clientId) {
      this.msAuthManager.init(this.config!.basePath, clientId);
    }
  }

  private async initCourseConfigs() {
    try {
      const courses = await this.exacompAPI.getCoursesForUser();
      this.store.dispatch(navigationActions.setCourses(courses));
    } catch (e) {
      GtnLogger.warn(e);
    }
  }

  private async initLanguageOverrides() {
    await this.translationManager.loadTranslationOverrides();
  }

  private checkForEnrolCode() {
    const queryParams = new URLSearchParams(window.location.search);
    const enrolCode = queryParams.get(CommonRoutingParams.ENROL_CODE) || this.store.getState().preferences.unusedEnrolCode;

    if (enrolCode) {
      if (this.store.getState().user.isLoggedIn) {
        this.appCommonAPI.enrolByCode(enrolCode).then((result) => {
          if (result.success) {
            this.store.dispatch(preferencesActions.set({ unusedEnrolCode: undefined }));
            alert(this.translationManager.translate('invite-students.successfully-enrolled'));
            window.setTimeout(() => {
              document.location.reload();
            }, 1000);
          }
        });
      } else {
        // Save enrol code until user is logged in
        this.store.dispatch(preferencesActions.set({ unusedEnrolCode: enrolCode }));
      }
    }
  }
}
